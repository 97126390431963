import { rootPoster, videos } from "./config";

export const posterSrc = videoItem =>
  `${rootPoster}${videoItem.id}${
    videoItem.noSuffix ? "" : "s"
  }.${videoItem.formatPoster || "png"}`;

export const trackVideo = videoItem => {
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "./api/?action=trackVideo", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  const params = Object.keys(videoItem)
    .map(key => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(videoItem[key]);
    })
    .join("&");
  xhr.send(params);
};
