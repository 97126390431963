import React, { useState } from "react";
import "./App.css";
import "video-react/dist/video-react.css"; // import css
import { videos } from "./config";
import logo from "./assets/images/logo.png";
import VideoPlayer from "./components/VideoPlayer.js";
import { posterSrc } from "./utils.js";

// import "./fonts/Disco3.ttf";
function App() {
  const [videoPlayer, setVideoPlayer] = useState();

  const handleClickVideo = videoItem => {
    console.log("handle", videoItem);
    setVideoPlayer(videoItem);
    window.scrollTo(0, 0);
  };

  const handleClickLogo = videoItem => {
    setVideoPlayer(videoItem);
  };
  return (
    <div className="App">
      <header>
        <img src={logo} className="logo" alt="logo canoflix" />
        <h1 onClick={handleClickLogo}>Gaël</h1>
      </header>

      {videoPlayer && (
        <div>
          <VideoPlayer videoItem={videoPlayer} />
        </div>
      )}

      <div className="list">
        {videos &&
          videos.map(videoItem => (
            <div key={videoItem.id} onClick={() => handleClickVideo(videoItem)}>
              <img src={posterSrc(videoItem)} alt={videoItem.title} />
              <p dangerouslySetInnerHTML={{ __html: videoItem.title }} />
            </div>
          ))}
      </div>
      <footer>
        <a href="#top">HAUT DE PAGE</a>
      </footer>
    </div>
  );
}

export default App;
