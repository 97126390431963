export const rootPoster =
  "https://lesfondamentaux.reseau-canope.fr/uploads/tx_cndpfondamentaux/";
export const rootVideos =
  "https://cdn.reseau-canope.fr/medias/lesfondamentaux/";
export const videos = [
  {
    id: "0444",
    title: "Découvrir le son [b] et sa graphie",
    catId: 1
  },
  {
    id: "0445",
    catId: 1,
    title: "Utiliser le son [b] et sa graphie",
    formatPoster: "jpg"
  },
  {
    id: "0446",
    catId: 1,
    title: "Découvrir le son [t] et sa graphie",
    formatPoster: "jpg"
  },
  {
    id: "0447",
    catId: 1,
    formatPoster: "jpg",
    title: "Utiliser le son [t] et sa graphie"
  },
  {
    id: "0448",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [&#643;] et sa graphie"
  },
  {
    id: "0449",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [&#643;] et sa graphie"
  },
  {
    id: "0450",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [&#658;] et ses graphies"
  },
  {
    id: "0451",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [&#658;] et ses graphies"
  },
  {
    id: "0452",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [m] et sa graphie"
  },
  {
    id: "0453",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [m] et sa graphie"
  },
  {
    id: "0454",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [l] et sa graphie"
  },
  {
    id: "0455",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [l] et sa graphie"
  },
  {
    id: "0456",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [R] et sa graphie"
  },
  {
    id: "0457",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [R] et sa graphie"
  },
  {
    id: "0458",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [p] et sa graphie"
  },
  {
    id: "0459",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [p] et sa graphie"
  },
  {
    id: "0460",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [d] et sa graphie"
  },
  {
    id: "0461",
    catId: 1,
    formatPoster: "jpg",
    title: "Utiliser le son [d] et sa graphie"
  },
  {
    id: "0462",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [n] et sa graphie"
  },
  {
    id: "0463",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [n] et sa graphie"
  },
  {
    id: "0464",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [&#626;] et sa graphie"
  },
  {
    id: "0465",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [&#626;] et sa graphie"
  },
  {
    id: "0466",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [k] et sa graphie"
  },
  {
    id: "0467",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [k] et sa graphie"
  },
  {
    id: "0468",
    catId: 1,
    formatPoster: "jpg",
    title: "Découvrir le son [g] et sa graphie"
  },
  {
    id: "0469",
    catId: 1,
    formatPoster: "jpg",
    title: "Utiliser le son [g] et sa graphie"
  },
  {
    id: "0470",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [f] et sa graphie"
  },
  {
    id: "0471",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [f] et sa graphie"
  },
  {
    id: "0472",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [v] et sa graphie"
  },
  {
    id: "0473",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [v] et sa graphie"
  },
  {
    id: "0474",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [s] et sa graphie (1/2)"
  },
  {
    id: "0475",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [s] et sa graphie (1/2)"
  },
  {
    id: "0476",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [s] et sa graphie (2/2)"
  },
  {
    id: "0477",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [s] et sa graphie (2/2)"
  },
  {
    id: "0478",
    formatPoster: "jpg",
    catId: 1,
    title: "Découvrir le son [z] et sa graphie"
  },
  {
    id: "0479",
    formatPoster: "jpg",
    catId: 1,
    title: "Utiliser le son [z] et sa graphie"
  },
  {
    id: "0414",
    catId: 1,
    title:
      "Découvrir les sons [&#603;&#771;] et [&#339;&#771;] et leurs graphies"
  },
  {
    id: "0415",
    catId: 1,
    title: "Découvrir le son [&#596;&#771;] et ses graphies"
  },
  {
    id: "0416",
    catId: 1,
    title: "Découvrir le son [a&#771;] et ses graphies"
  },
  {
    id: "0417",
    catId: 1,
    title: "Découvrir le son [w] et ses graphies"
  },
  {
    id: "0418",
    catId: 1,
    title: "Découvrir le son [j] et ses graphies (1/2)"
  },
  {
    id: "0419",
    catId: 1,
    title: "Découvrir le son [j] et ses graphies (2/2)"
  },
  {
    id: "0420",
    catId: 1,
    title: "Découvrir le son [w&#603;&#771;] et sa graphie"
  },
  {
    id: "0393",
    catId: 1,
    title: "Découvrir le son [a] et sa graphie"
  },
  {
    id: "0394",
    catId: 1,
    title: "Découvrir le son [i] et sa graphie"
  },
  {
    id: "0395",
    catId: 1,
    title: "Découvrir le son [o] et sa graphie"
  },
  {
    id: "0396",
    catId: 1,
    title: "Découvrir le son [e] et sa graphie"
  },
  {
    id: "0397",
    catId: 1,
    title: "Découvrir le son [&#603;] et sa graphie (1/2)"
  },
  {
    id: "0398",
    catId: 1,
    title: "Découvrir le son [&#603;] et sa graphie (2/2)"
  },
  {
    id: "0399",
    catId: 1,
    title: "Découvrir le son [y] et sa graphie"
  },
  {
    id: "0400",
    catId: 1,
    title: "Découvrir les sons [ ø] et [&#601;] et leurs graphies"
  },
  {
    id: "0401",
    catId: 1,
    title: "Découvrir le son [u] et sa graphie "
  },
  {
    id: "0402",
    catId: 1,
    title: "Découvrir le son [œ] et ses graphies "
  },
  {
    id: "0090",
    catId: 2,
    title: "Les nombres de 0 à 9"
  },
  {
    id: "0091",
    catId: 2,
    title: "Décomposer les nombres de 1 à 9"
  },
  {
    id: "0092",
    catId: 2,
    title: "Le nombre 10"
  },

  {
    id: "0096_01",
    catId: 2,
    title: "Construire la dizaine",
    noSuffix: true
  },
  {
    id: "0097_01",
    catId: 2,
    title: "Nombres de 10 à 69",
    noSuffix: true
  },
  {
    id: "0098_01",
    catId: 2,
    title: "Nombres de 70 à 79",
    noSuffix: true
  },
  {
    id: "0099_01",
    catId: 2,
    title: "Nombres de 80 à 99",
    noSuffix: true
  },
  {
    id: "0100_01",
    catId: 2,
    title: "Décomposer les nombres de 10 à 99",
    noSuffix: true
  },
  {
    id: "0093_02",
    catId: 2,
    title: "Comparer 2 nombres de 0 à 9",
    noSuffix: true
  },
  {
    id: "0094_01",
    catId: 2,
    title: "Ranger plusieurs nombres de 0 à 9",
    noSuffix: true
  },
  {
    id: "0095",
    catId: 2,
    title: "Encadrer les nombres entre 0 et 9",
    noSuffix: true
  },

  {
    id: "0101_01",
    catId: 2,
    title: "Placer un nombre de 10 à 99 sur la droite graduée",
    noSuffix: true
  },
  {
    id: "0102_01",
    catId: 2,
    title: "Comparer deux nombres à deux chiffres",
    noSuffix: true
  },
  {
    id: "0103_01",
    catId: 2,
    title: "Ranger plusieurs nombres à deux chiffres",
    noSuffix: true
  },
  {
    id: "0104_01",
    catId: 2,
    title: "Encadrer les nombres à deux chiffres",
    noSuffix: true
  },
  {
    id: "0376",
    formatPoster: "jpg",
    catId: 2,
    title: "Le nombre 100"
  },
  {
    id: "0377",
    formatPoster: "jpg",
    catId: 2,
    title: "Les nombres de 100 à 999"
  },
  {
    id: "0378",
    formatPoster: "jpg",
    catId: 2,
    title: "Le nombre 1000"
  },
  {
    id: "0379",
    formatPoster: "jpg",
    catId: 2,
    title: "Les nombres de 1 000 à 9 999"
  },
  {
    id: "0380",
    formatPoster: "jpg",
    catId: 2,
    title: "Les nombres de 10 000 à 999 999"
  },
  {
    id: "0381",
    formatPoster: "jpg",
    catId: 2,
    title: "Les grands nombres : les millions"
  },
  {
    id: "0382",
    formatPoster: "jpg",
    catId: 2,
    title: "Les grands nombres : les milliards"
  }
];

const categories = [
  {
    id: 1,
    name: "Lecture"
  },
  {
    id: 2,
    name: "Nombres"
  }
];
