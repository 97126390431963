import React, { useEffect, useRef } from "react";
import { Player } from "video-react";
import { posterSrc, trackVideo } from "../utils.js";
import { rootVideos } from "../config";

const VideoPlayer = ({ videoItem }) => {
  // player must be declared here so the ref callback can refer to it
  let player = useRef(null);
  let isTracked = false;
  const subscriber = state => {
    if (state.hasStarted) {
      if (!isTracked) {
        trackVideo(videoItem);
        isTracked = true;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => player.subscribeToStateChange(subscriber), 1000);
  });
  return (
    <div className="videoPlayer">
      <Player
        playsInline
        poster={posterSrc(videoItem)}
        src={`${rootVideos}${videoItem.id}_sd.mp4`}
        ref={playerRef => (player = playerRef)}
      />
    </div>
  );
};

export default VideoPlayer;
